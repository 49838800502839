import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-mensaje-error',
  templateUrl: './mensaje-error.component.html',
  styleUrls: ['./mensaje-error.component.scss']
})
export class MensajeErrorComponent implements OnInit {

  mensaje:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mensaje=data.strMensaje;
   }

  ngOnInit() {
    //console.log(this.data);
    //this.mensaje
  }

  close(){
    this.thisDialogRef.close();
  }

}
