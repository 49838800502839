import { Component, OnInit } from '@angular/core';
import { CRespuesta } from './shared/crespuesta';
import { User } from '../core/models/user.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StorageService } from '../core/services/storage.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CRespuestaService } from './shared/CRespuesta.service';
import { Session } from '../core/models/session.model';
import { Token } from '../core/models/token';
import { copyStyles } from '@angular/animations/browser/src/util';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //public miCRespuesta:CRespuesta=new CRespuesta();
  //isLoginError : boolean = false;
  mensaje:string;
  //public userAcces:User[]=[];
  strUserName:string;
  strPassword:string;
  //token:string;
  //miSesion:Session=new Session();
  //misDatosSesion:Token=new Token();

  showSpinner: boolean = false;
  showForm:boolean=true;
  //mostrar:boolean=true;

  public loginForm: FormGroup;
  //public submitted: Boolean = false;
  cookie:string;
  //miCookie:string;

  //mostrarPassword:boolean=true;
  //ocultarPassword:boolean=false;

  hide = true;
  mistrUsuario:string;
  mistrPassword:string;


  title:string;

  constructor(private formBuilder: FormBuilder,private storageService: StorageService,private route: ActivatedRoute,
    private router: Router,private CRespuestaService:CRespuestaService,public dialog: MatDialog) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
      });
     }

  ngOnInit() {

    this.loginForm  = this.formBuilder.group({

      username: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.maxLength(40),
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),

      password: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(6),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
      ])),

    });
  }

  keyDownFunction(event) {

    if(event.key === "Enter"){
      this.submitLogin();
    }
  }
  
submitLogin(){
  if(this.loginForm.valid){  
       
       
    this.showSpinner = true;
     this.CRespuestaService.apiUsuario(this.cookie).subscribe((datos:any)=>{
       //console.log(datos);
       if(datos.strCookie!=null){

        let strSecurityStampHash=datos.strContrasena;
        this.DesencriptaPassword(strSecurityStampHash);

        let strUsuarioEncriptado=datos.strUsuario;
        this.DesencriptaUsuario(strUsuarioEncriptado);
        //console.log(this.mistrUsuario,this.mistrPassword)

        let miCookie=datos.strCookie;
         this.CRespuestaService.apiSesion(this.strUserName,this.strPassword,miCookie).subscribe((data:any)=>{
           //console.log(data);
           if(data.strMensaje==="OK"){

            this.showForm=false;
            
              //  setTimeout(() => {
              //console.log(this.mistrUsuario,this.mistrPassword)
             this.CRespuestaService.token(this.mistrUsuario,this.mistrPassword).subscribe((datos:any)=>{
               //console.log(datos)
               data.expires=datos[".expires"];
                data.strToken=datos.access_token;
                this.correctLogin(data);
                //this.storageService.setCurrentSession(data);
                //this.router.navigate(['/comandas']);

                //this.showSpinner=true;
                //this.showForm=false;
             })
                 
                 //this.router.navigate(['/comandas']);
              //  }, 4000); 
             
           }else{
             //this.mensaje=data.strMensaje;
             this.mensajeError(data.strMensaje);
             this.showSpinner=false;
           }
         })
       }else{
        this.mensajeError('Por favor vuelve a solicitar la cookie leyendo el codigo QR.');
        this.showSpinner=false;
       }
     })
  }
  
 }

 private mensajeError(data:any){
  let dialogRef = this.dialog.open(MensajeErrorComponent, {
    width: '600px',
    data: {
      'strMensaje':data
    }
  });
  dialogRef.afterClosed().subscribe(() => {
    //location.href = "http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId;
    //location.href = "http://infiteccontrol.infitec.es/servicios/solicita?est=2148&pro=12688";
  });
 }

 private DesencriptaPassword(strSecurityStampHash:string){
  this.mistrPassword = "";  

// Solo continuo si existe strSecurityStampHash
if (strSecurityStampHash != null && strSecurityStampHash != "")
{
  strSecurityStampHash=strSecurityStampHash.split('').reverse().join('');
  strSecurityStampHash = strSecurityStampHash.substr(5, strSecurityStampHash.length - (2 * 5));

  let i:number;
  for (i = 0; i < strSecurityStampHash.length; i++)
  {
    if (i % 2 == 0) { this.mistrPassword += strSecurityStampHash.substr(i, 1); }
  }
}

return this.mistrPassword;
}

private DesencriptaUsuario(strUsuarioEncriptado:string){
  this.mistrUsuario = "";    

if (strUsuarioEncriptado != null && strUsuarioEncriptado != "")
{
  strUsuarioEncriptado=strUsuarioEncriptado.split('').reverse().join('');
  strUsuarioEncriptado = strUsuarioEncriptado.substr(5, strUsuarioEncriptado.length - (2 * 5));

  let i:number;
  for (i = 0; i < strUsuarioEncriptado.length; i++)
  {
    if (i % 2 == 0) { this.mistrUsuario += strUsuarioEncriptado.substr(i, 1); }
  }
}

return this.mistrUsuario;
}

 public getTitle(){
  this.title = document.title = this.storageService.getCurrentSession().strNombreTitulo;
  //console.log(this.title);
}

  private correctLogin(data: Session){
    this.storageService.setCurrentSession(data);

    // this.showSpinner = true;
    this.router.navigate(['comandas']);
    this.showSpinner = false;
  }

  createUserLogin(){
    this.router.navigate(['/create-user'])
  }


  recuperacionPassword(){        
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.cookie,
      }
    }
    this.router.navigate(['/recuperacioncontrasena'], extra);
  }

}
