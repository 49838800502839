import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Boton } from '../core/models/boton';
import { BotonesService } from '../core/services/botones.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Establecimiento } from '../core/models/establecimiento';

@Component({
  selector: 'app-botones',
  templateUrl: './botones.component.html',
  styleUrls: ['./botones.component.scss']
})
export class BotonesComponent implements OnInit {

  listBotones:Boton[]=[];
  listEstablecimiento:Establecimiento[]=[];

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;


  constructor(private miservicio:BotonesService,private route: ActivatedRoute,private router: Router,public dialog: MatDialog) { }

  ngOnInit() {  
    //console.log(sessionStorage.getItem('strCookie'));
    //console.log(sessionStorage.getItem('intMesaId'));
    this.listarBotones(); 
    this.datosEstablecimiento(); 
  }

  listarBotones(){
    this.miservicio.getBotones(sessionStorage.getItem('strCookieServiciosMesa')).pipe(first()).subscribe(datos=>{
      this.listBotones=datos;
      //console.log(this.listBotones)
    })
  }

  datosEstablecimiento(){
    this.miservicio.getEstablecimiento(sessionStorage.getItem('strCookieCartaOnline')).pipe(first()).subscribe(datos=>{     
      this.listEstablecimiento=datos;
      //console.log(this.listEstablecimiento);
      this.colorCuerpo="#"+this.listEstablecimiento[0].strColorCuerpo;
      this.colorTextoCuerpo="#"+this.listEstablecimiento[0].strColorTextoCuerpo;
      this.colorMenu="#"+this.listEstablecimiento[0].strColorMenu;
      this.colorTextoMenu="#"+this.listEstablecimiento[0].strColorTextoMenu;
    })
  }

  pulsar(boton){
    this.miservicio.llamaServicio(sessionStorage.getItem('strCookieServiciosMesa'),boton,sessionStorage.getItem('intMesaIdServiciosMesa')).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos)
      if(datos.booOk===true){        
        this.router.navigate(['pedidoMesa']);         
      }else{
        let extra: NavigationExtras = {
          queryParams: {
            'time':datos.strValor       
          }
      }
        this.router.navigate(["errorTiempo"],extra);
      }
    })
  }

}
