import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { ComandaService } from '../core/services/comanda.service';
import { first } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Resultcomanda } from '../core/models/resultcomanda';
import { Estadolineacomada } from '../core/models/estadolineacomada';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Session } from '../core/models/session.model';
import { MatDialog } from '@angular/material';
import { SesionCaducadaComponent } from '../sesion-caducada/sesion-caducada.component';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';

@Component({
  selector: 'app-comanda',
  templateUrl: './comanda.component.html',
  styleUrls: ['./comanda.component.scss']
})
export class ComandaComponent implements OnInit {

  date = new FormControl(new Date());
  miFecha =  new Date();
  misComandas:Resultcomanda[]=[];
  dataTodo:Resultcomanda[]=[];
  listEstadosComanda:Estadolineacomada[]=[];
  button1:boolean=true;
  button2:boolean=false;
  holder:string;
  @ViewChild('Button1') Button1: ElementRef;
  disabled = false;

  listLineasUrgentes:any[]=[];
  strUrgente:string;
  private _hubConnection: HubConnection;

  audio = new Audio();
  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  @ViewChild('submit') buttonPlay: ElementRef;

  comandasExistentes=false;
  errorComandas=false;
  dataMensaje="";

  constructor(private storageService: StorageService, private miServicio:ComandaService,public datepipe: DatePipe,public dialog: MatDialog) { }

  ngOnInit() {
    var event = new Date(this.storageService.getCurrentSession().expires);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    //console.log(jsonDate)
    //console.log(today)
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    } 
  
    
    
    this.listarEstadosComanda();
    this.listarComandas();
    
    //console.log(this.date.value.toLocaleDateString());


    this._hubConnection = new HubConnectionBuilder().withUrl(this.storageService.getCurrentSession().strHostSignalR+'/comandas').build();
    this._hubConnection
      .start()
      .then()
      .catch(err=>{
        // alert('Se ha cerrado la conexión, comprueba que tienes internet...');
        // window.location.reload();
        
      });

    this._hubConnection.on('BroadcastMessage2', (value: any) => {
      //this.listarTickets();
      //console.log(type)
      this.listarComandas();
    });


    this._hubConnection.onclose(async(error)=>{
      //console.log("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId);
      //alert('se ha perdido la conexion de internet');
      // let dialogRef = this.dialog.open(ErrorWifiComponent, {
      //   width: '600px'      
      // });
      // dialogRef.afterClosed().subscribe(() => {        
        // window.location.reload();
      // });

      //this.salir();
      // if(window.confirm('Comprueba que tienes conexion a internet...')){
      //   window.location.reload();
      // }else{
      //   window.location.reload();
      // }

      //alert('Comprueba que tienes conexion a internet...');

      if (typeof error !== 'undefined') {
        // window.location.reload();
        console.log(error)
      } else {
        // window.location.reload();
      }

      

      //ANTES DE BORRAR EL OBJETO DE SESION, EXTRAIGO EL NUMERO DEL ESTABLECIMIENTO PARA REDIRIGIRLE A INFITEC CONTROL Y QUE REALICE 
      //NUEVAMENTE EL PROCESO DE SOLICITUD DE COOKIE
      //('http://infiteccontrol.infitec.es/servicios/solicita?est=2148&pro=12688')

      //BORRO EL OBJETO DE LA SESION
      //this.storageService.removeCurrentSession();
      //window.open("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId +" ");
      

      //__________  PROCESO PARA INTENTAR RESTABLECER LA CONEXION  ___________/
      //setTimeout(function(){       
      //   this._hubConnection.start().then().catch();

      //   this._hubConnection.on('BroadcastMessage2', (type: any, payload: string) => {
      //     this.listarComandas();
      // });
      //},3000); 

    });


    // this.startAudio();
  }

  openWindow(){
    let dialogRef = this.dialog.open(SesionCaducadaComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(r => {
      //console.log('redirige al login')
      //window.open("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId +" ")
    });
  }

  mensajeError(data){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(r => {
      //console.log('redirige al login')
      //window.open("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId +" ")
    });
  }

  ventanaAudio(){
    var audio = new Audio();
    audio.src = '../../../assets/emergency.mp3';

    audio.oncanplaythrough = (event) => {
      var playedPromise = audio.play();
      if (playedPromise) {
          playedPromise.catch((e) => {
              // console.log(e)
              if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                  console.log(e.name);
              }
          }).then(() => {

          });
      }
    }
  }

  listarComandas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    this.miServicio.getComandas(miFechaConvert,'N').subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.errorComandas=false;
        this.comandasExistentes=true;
        this.misComandas=datos.miObjectResult.miListTpvTicketResult;
        // console.log(datos.miListTpvTicketResult[0])
        if(datos.miObjectResult.booComandaNueva){
            // console.log('suena ' + new Date().getMilliseconds() );
            document.getElementById("buttonAudio").click();
        }
      }else{
        // this.mensajeError(datos.miRespuesta.strMensaje)
        this.dataMensaje=datos.miRespuesta.strMensaje;
        this.errorComandas=true;
        this.comandasExistentes=false;
      }
      
    })
  }

  listarComandasFinalizadas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    this.miServicio.getComandas(miFechaConvert,'S').subscribe(datos=>{
      this.misComandas=datos.miObjectResult.miListTpvTicketResult;
    })
  }

  listarEstadosComanda(){
    this.miServicio.getEstadosComandaLinea().subscribe(datos=>{
      this.listEstadosComanda=datos;
    })
  }

  cambiaLineaTicket(cl){
    //console.log(cl)
    // console.log(cl.descripcion.charAt(0))
    this.strUrgente="null";

    if(cl.descripcion.charAt(0)!="*" && cl.descripcion.charAt(0)!="#"){
      this.miServicio.putLineaTicket(cl,this.strUrgente).subscribe((datos:any)=>{
        //console.log(datos)
      })
    }    
  }

  f1(c){
    c.miListCTicketLinea.forEach(e => {
      document.getElementById(e.id_ticket_linea).style.display="initial"
    });   
    this.button1=false;
    this.button2=true; 
  }

  f2(c){
   
    c.miListCTicketLinea.forEach(e => {
      document.getElementById(e.id_ticket_linea).style.display="none";
    });    
    this.button2=false;
    this.button1=true;

    this.strUrgente="S";

    this.miServicio.putLineaTicket(this.listLineasUrgentes,this.strUrgente).subscribe((datos:any)=>{
      let myObject:any;
      myObject=datos[0];

      let miTicket=c.miCTicket.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
      }

      this.listLineasUrgentes.splice(0, this.listLineasUrgentes.length);
    })

  }

  marcarItem2(event: any,cl)
  {

    if(event.checked===true){
      this.listLineasUrgentes.push(event.source.id);

    }else{
      const index = this.listLineasUrgentes.indexOf(event.source.id, 0);
        if (index > -1) {
          this.listLineasUrgentes.splice(index, 1);
        }
    }
  }

  prepararComanda(c){
    // console.log(c)
    if(c.strTestoBoton==="Preparar"){      
      this.miServicio.putTicket(c,'X').subscribe((datos:any)=>{
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.misComandas.splice(index, 1, myObject);
        }
      });
    }if(c.strTestoBoton==="Terminar"){
      this.miServicio.putTicket(c,'F').subscribe((datos:any)=>{
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length===0){      
          this.misComandas.splice(index, 1);
        }
      });
    }
  }

  finalizarComanda(c){
    //console.log('boton2')
    /*this.miServicio.putTicket(c,'F').subscribe((datos:any)=>{
      //console.log(datos);
      let myObject:any;
      myObject=datos[0];

      let miTicket=c.miCTicket.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
      }
    });*/
    //document.getElementById(c.miCTicket.n_ticket).style.display="inline-block";
    //document.getElementById(c.miCTicket.id_ticket).style.display="none";
  }

  pendientes(){
    document.getElementById('pendiente').style.color="#666666";
    document.getElementById('finalizada').style.color="#b3b3b3";
    this.listarComandas();
  }

  finalizadas(){
    document.getElementById('finalizada').style.color="#666666";
    document.getElementById('pendiente').style.color="#b3b3b3";
    this.listarComandasFinalizadas();
  }

  salir(){
    this.storageService.logout();
  }

}
