import { BrowserModule, Title }  from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
//import { FileSelectDirective } from 'ng2-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { LoginComponent } from './login/login.component';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { CRespuestaService } from './login/shared/CRespuesta.service';
import { MatNativeDateModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { BotonesComponent } from './botones/botones.component';
import { CookieService } from 'ngx-cookie-service';
import { ComandaComponent } from './comanda/comanda.component';
import { MensajeErrorComponent } from './mensaje-error/mensaje-error.component';
import { DatePipe } from '@angular/common';
import { LoginRecuperacionComponent } from './login-recuperacion/login-recuperacion.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { SesionCaducadaComponent } from './sesion-caducada/sesion-caducada.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { RouterModule, Routes } from '@angular/router';

const appRoutes:Routes=[
   /************ componentes que se pueden cargar sin loguearse ***********/
   { path: 'login', component: LoginComponent },
   { path: 'footer', component: FooterComponent },
   //{ path: 'inicio', component: InicioComponent },
   //{ path: 'ServciosMesa', component: BotonesComponent },
   { path: 'mensajeError', component: MensajeErrorComponent },
   { path: 'cambiocontrasena', component: CambiaContrasenaComponent },
   { path: 'recuperacioncontrasena', component: LoginRecuperacionComponent },
   { path: 'error-sesion', component: SesionCaducadaComponent },
 
 
     /************************* rutas que necesitan autorizacion ************************/
 
   { path: 'comandas', component: ComandaComponent,canActivate: [ AuthorizatedGuard ]},
 
   /************************* Estos son rutas por defecto ************************/
 
   { path: '', redirectTo: '/login', pathMatch: 'full' },
   { path: '**', redirectTo: '/login'}
]; 


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    InicioComponent,
    BotonesComponent,
    ComandaComponent,
    MensajeErrorComponent,
    LoginRecuperacionComponent,
    CambiaContrasenaComponent,
    SesionCaducadaComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    FormsModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule.forRoot(
      appRoutes,
      {enableTracing:true}
    )
  ],
  providers: [CRespuestaService,Title,CookieService,DatePipe],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
