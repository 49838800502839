import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Boton } from '../models/boton';
import { Establecimiento } from '../models/establecimiento';

@Injectable({
  providedIn: 'root'
})
export class BotonesService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  getBotones(cookie):Observable<Boton[]>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'Servicio?'+dato;
    return this.http.get<Boton[]>(urlCompleta,  this.httpOptions);
  }

  getEstablecimiento(cookie):Observable<Establecimiento[]>{ 
    let dato='strCookie='+cookie+'&datoAdicional=1';
    let urlCompleta=this.url+'establecimiento?'+dato;
 
    return this.http.get<Establecimiento[]>(urlCompleta,  this.httpOptions);
  }

  llamaServicio(cookie,boton:Boton,mesa):Observable<any>  {
     
      let urlCompleta=this.url+'Servicio?'+"strCookie="+cookie ;
  
      let dato:any = {};
      dato.intProductoId = boton.intId;
      dato.strProductoNombre=boton.strNombre;
      dato.strDatos=mesa + ", " + boton.strDescripcion;
      dato.strDescargado="";
      dato.intMesaId=mesa;
  
      var jsonString = JSON.stringify(dato);
  
      return this.http.post<any>(urlCompleta,jsonString,this.httpOptions);
    
  }
  
}
