import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { Resultcomanda } from '../models/resultcomanda';
import { Estadolineacomada } from '../models/estadolineacomada';
import { CTicketLinea } from '../models/cticket-linea';
import { ObjectResult } from '../models/object-result';
import { environment } from 'src/environments/environment';
import { ComandaResult } from '../models/comanda-result';

@Injectable({
  providedIn: 'root'
})
export class ComandaService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  private hostSignalR=this.storageService.getCurrentSession().strHostSignalR;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  postAspCore(data): Observable<any>{
    //let url="http://localhost:5000/api/values";
    let url=this.hostSignalR+"/api/message";
    let dato = '{"Type":"'+ data +'", "Payload":""}';
    let headers = new HttpHeaders().set('Content-Type','application/json');
     
    return this.http.put<any>(url, dato, {headers: headers, responseType:'text' as 'json'});
  } 


  getComandas(miFechaConvert,strFinalizada):Observable<ComandaResult>{
    let dato='strCookie='+this.cookie+'&strTicketId='+'&strPeriodo='+'&strCaja=';
    dato=dato+'&strFechaDesde='+miFechaConvert+'&strFechaHasta='+miFechaConvert+'&strParametroLike=';
    //dato=dato+'&strFechaDesde=2018-02-26&strFechaHasta=2018-02-26&strParametroLike=';
    dato=dato+'&strTicketIdLinea='+'&strParametroLikeLinea='+'&strFinalizado='+strFinalizada;
    let urlCompleta=this.url+'CTicket?'+dato;
 
    return this.http.get<ComandaResult>(urlCompleta,this.httpOptions);
  }

  getEstadosComandaLinea():Observable<Estadolineacomada[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'EstadoLineaComanda?'+dato;
 
    return this.http.get<Estadolineacomada[]>(urlCompleta,this.httpOptions);
  }

  putLineaTicket(cl:any,strUrgente):Observable<Resultcomanda>{
    let urlCompleta;
    let miDato;


    if(cl.length>0){
      
      
      let miList:any[]=[];
      cl.forEach(i => {
        let dato:any = {};
        dato.id_ticket_linea = i;
        miList.push(dato);
  
      });
        
      var jsonString = JSON.stringify(miList);
      miDato='&miListCTicketLinea=' + jsonString;

      urlCompleta=this.url+'CTicket/cocina?strCookie='+this.cookie+'&strTicketId='+cl[0].id_ticket;
      urlCompleta=urlCompleta+'&strTicketIdLinea='+cl.id_ticket_linea+'&strEstadoComanda=&strUrgente='+strUrgente;
      urlCompleta=urlCompleta+'&miListCTicketLinea=' + jsonString;
  
      //let miDato2='&miListCTicketLinea=[{id_ticket_linea:1},{id_ticket_linea:2}]';
    }else{

      urlCompleta=this.url+'CTicket/cocina?strCookie='+this.cookie+'&strTicketId='+cl.id_ticket;
      urlCompleta=urlCompleta+'&strTicketIdLinea='+cl.id_ticket_linea+'&strEstadoComanda=&strUrgente='+strUrgente;
      urlCompleta=urlCompleta+'&miListCTicketLinea=';
    }

    return this.http.get<Resultcomanda>(urlCompleta, this.httpOptions);
  }

  putTicket(c:Resultcomanda,strEstado):Observable<Resultcomanda>{
    let urlCompleta=this.url+'CTicket/cocina?strCookie='+this.cookie+'&strTicketId='+c.miCTicket.id_ticket;
    urlCompleta=urlCompleta+'&strTicketIdLinea=&strEstadoComanda='+strEstado+'&strUrgente=&miListCTicketLinea=';
    //let dato = '{"intId":"'+ limpieza.intId +'", "intEstadoLimpiezaId":'+ limpieza.intEstadoLimpiezaId +', "strNotas":"'+ limpieza.strNotas +'", "strHoraInicio":"'+ limpieza.strHoraInicio +'", "strHoraFin":"'+ limpieza.strHoraFin +'"}'; 

    return this.http.get<Resultcomanda>(urlCompleta, this.httpOptions);
  }

}
