import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { StorageService } from '../core/services/storage.service';

@Component({
  selector: 'app-sesion-caducada',
  templateUrl: './sesion-caducada.component.html',
  styleUrls: ['./sesion-caducada.component.scss']
})
export class SesionCaducadaComponent implements OnInit {

  cookie:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,private storageService: StorageService) {}

  ngOnInit() {
    //console.log(this.cookie);
  }

  public logout(): void{
    this.thisDialogRef.close();
    setTimeout(() => {
      //this.storageService.logout();
      location.href = "http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId;
      //window.open("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId +" ")
    }, /*2000*/);  
    //this.storageService.removeCurrentSession();
  }

}
